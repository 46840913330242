<template>
  <div class="wrapper">
    <div class="title">
      <title-info condensed :info="this.tooltipInfo">
        {{ title }}
      </title-info>
    </div>
    <div class="bar-container">
      <div class="bar" v-for="(percentageData, index) in percentage" :key="index">
        <div style="height: 200px; position: relative">
          <!-- Dashed line indicator for each bar based on norm -->
          <div
            class="dashed-line"
            :style="{ bottom: `${norm[index]}%` }"
          ></div>
          <!-- Bar graph -->
          <div
            class="bar-graph"
            :style="{
              height: `${percentageData.percentage}%`,
              zIndex: 1
            }"
          ></div>
        </div>
        <div class="seconds text-center">
          <p>{{ percentageData.secondsWatched }} {{ $t("page.results.sec") }}</p>
        </div>
        <div class="percentage text-center">
          <h4 class="">{{ percentageData.percentage }}%</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleInfo from "./TitleInfo.vue";
export default {
  components: {
    TitleInfo,
  },
  props: {
    percentage: {
      required: true,
    },
    title: {
      required: true,
    },
    tooltipInfo: {
      required: true,
    },
    norm: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.text-center {
  text-align: center;
}

.bar-graph {
  width: 42px;
  background-color: #004065;
  bottom: 0px;
  position: absolute;
}

.seconds {
  text-align: center;
  font: small;
  opacity: 0.8;
}
.bar-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.bar {
  height: 100%;
  margin: 10px;
  position: relative;
}

.dashed-line {
  position: absolute;
  width: 126%;
  margin-left:-16%;
  border-top: 3px dashed grey; /* Adjust color and thickness */
  z-index: 2; /* Ensure it is above the bar-graph */
}
</style>
